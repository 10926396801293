// src/components/Projects.js

import React from 'react';

function Projects() {
  return (
    <div className="container">
      <h1>Projects</h1>
      <p>My cool projects</p>
      {/* Project items will go here */}
    </div>
  );
}

export default Projects;
