// src/components/Home.js

import React from 'react';

// Example for the Home component
function Home() {
  return (
    <div className="container">
      <h1>Welcome to Max Hovding's Supercool Website</h1>
      <p>Lorem ipsum dolor sit amet...</p>
    </div>
  );
}


export default Home;
