// src/components/Contact.js

import React from 'react';
// Import the image


function Contact() {
  return (
    <div className="container">
      <h1>Contact Me</h1>
      <p>You can reach me via email at max@hovding.no</p>
    
      
      {/* Contact form or additional contact information */}
    </div>
  );
}

export default Contact;
