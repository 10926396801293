// src/components/About.js

import React from 'react';

function About() {
  return (
    <div className="container">
      <h1>About Me</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
  );
}

export default About;
