import React from 'react';

import KanyeTrump from '../assets/kanyetrump.png';


// Sample additional images, replace with actual imports
// import SecondPostImage from '../assets/secondPostImage.png';
// import ThirdPostImage from '../assets/thirdPostImage.png';

function Blog() {
  // Array of blog post data
  const blogPosts = [
    {
      id: 1,
      title: "Lorem ipsum dolor sit amet",
      date: "",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      image: KanyeTrump
    },
    {
      id: 2,
      title: "Consectetur adipiscing elit",
      date: "",
      content: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      image: "PlaceholderImage2.jpg"
    },
    {
      id: 3,
      title: "Sed do eiusmod tempor incididunt",
      date: "",
      content: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      image: "PlaceholderImage3.jpg"
    }
  ];



  return (
    <div className="container">
      <h1>Blog</h1>
      {/* Rendering the blog posts dynamically */}
      {blogPosts.map((post) => (
        <article key={post.id}>
          <h2>{post.title}</h2>
          <p><em>{post.date}</em></p>
          <p>{post.content}</p>
          {post.image && <img src={post.image} alt={post.title} style={{ maxWidth: '100%', height: 'auto' }} />}
        </article>
      ))}
    </div>
  );
}

export default Blog;
